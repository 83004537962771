import Locale, {
  ExploreTasksPage,
  HomePage,
  MenuLocale,
  MyTasksPage,
  NewTaskForm,
  SettingsPage,
  Task,
} from "../../Models/Locale";

// Northern Sami

export const seNO = Locale.create({
  locale: "se-NO",
  name: "Davvisámegiella",
  teacherPortal: "Oahpaheaddjiportála",
  menu: MenuLocale.create({
    newTask: "Ođđa bargobihttá",
    myTasks: "Mu bargobihtát",
    exploreTasks: "Dutkka bargobihtáid",
    myProjects: "Mu prošeavttat",
    home: "Ruoktot",
    signOut: "Logge olggos",
  }),
  newTaskForm: NewTaskForm.create({
    language: "Giella",
    title: "Namma",
    description: "Čilgehus",
    themes: "Fáttát",
    subjects: "Fága",
    ageRange: "Ahkejoavku",
    timeRange: "Áigeárvvoštallan",
    teacherDescriptionTitle: "Ulbmil oahpahusain",
    teacherDescriptionPlaceholder:
      "oahpaheapmi",
    studentDescriptionTitle: "Bargobihttáčálus",
    studentDescriptionPlaceholder:
      "Bárgobihttá oahppiide",
    saveButtonTooltip: "Vurke",
    previewButtonTooltip: "Ovdačájeheapmi",
    clearButtonTooltip: "Guorus skovvi",
    backButtonTooltip: "Maŋás ",
    titleValidationErrorMessage: "Dát eahpelihkostuvai: Bargobihtás váilu namma"
  }),
  homePage: HomePage.create({
    title: "Ludenco Create Oahppoportála",
    aboutLudensoCreateTitle: "Mii lea Ludenso Create?",
    aboutLudensoCreateDescription:
      "Ludenso Create diktá mánáid birra máilmmi hutkat 3D ja AD bokte",
    navigateToLudensoCreateButtonText: "Geahččal Ludenso Create!",
    aboutLudensoCreateTeacherPortalTitle: "Mii lea oahpaheaddjeportála?",
    aboutLudensoCreateTeacherPortalDescription:
      "Oahpaheaddjiportála lea ráhkaduvvon oahpaheaddjiid movttiidahttit, gávdnat ja ráhkadit pedagogalaš fáttáid Ludenso Creatii mat leat heivehuvvon daid ođđa oahpaheaddjeplána mihttomeriide.",
    featuredTaskTitle: "Váljejuvvon  bargobihttá",
  }),
  exploreTasksPage: ExploreTasksPage.create({
    title: "Oza bargobihtáid",
    filterOnAge: "Sirre agi mielde:",
    filterOnSubject: "Sirre fága mielde:",
    filterOnThemes: "Sirre fáttá mielde:",
    estimatedtime: "Árvvoštallon áigi",
    hours: "diimmut",
    years: "jahki",
    printButtonTooltip: "Čálit",
    backButtonTooltip: "Maŋás",
    teacherViewButtonTooltip: "Oahpaheaddjeustitlaš",
    studentViewButtonTooltip: "Oahppiustitlaš",
    makeVariantButtonTooltip: "Ráhkat iežat varianttá bargobihtás",
  }),
  myTasksPage: MyTasksPage.create({
    title: "Mu bargobihtát",
    editButtonTooltip: "Divo bargobihttá",
    printButtonTooltip: "Čálit",
    backButtonTooltip: "Maŋás",
    teacherViewButtonTooltip: "Oahpaheaddjeustitlaš",
    studentViewButtonTooltip: "Oahppiustitlaš",
  }),
  settingsPage: SettingsPage.create({
    title: "Heivehusat",
    selectLanguage: "Vállje giela",
  }),
  task: Task.create({
    availableAgeRanges: [
      [6, 7],
      [8, 9],
      [10, 12],
      [13, 15],
      [16, 18],
    ],
    availableSubjects: [
      "Matematihkka",
      "Luonddufága",
      "Dárgogiella",
      "Dáidda- ja giehtaduodjefága",
      "Historjá",
      "Servodatfága",
      "Geografiija",
      "Entreprenevradoaibma",
      "KREE"
    ],
    availableThemes: [
      "Guoddevaš ovdáneapmi",
      "Demokratiija ja borgárvuohta",
      "Álbmotdearvvašvuohta ja eallinhálddašeapmi",
      "Teknologiija",
      "Entreprenevradoaibma",
      "Ovttasbargu",
      "Kommunikašuvdna",
      "Moaittlaš jurddašeapmi",
      "21st century skills"
    ],
  }),
});
