import { getParent, types } from "mobx-state-tree";
import axios from "axios";
import Cookies from "js-cookie";
import { Cookie } from "../utils/Cookie";
import { BaseConnectionString } from "../utils/ConnectionStrings";

const TokenStore = types
  .model("TokenStore", {
    AuthenticationToken: types.maybeNull(types.string),
    RefreshToken: types.maybeNull(types.string),
  })
  .actions((self) => ({
    SetTokens(authenticationToken, refreshToken) {
      self.AuthenticationToken = authenticationToken;
      self.RefreshToken = refreshToken;
      if (authenticationToken !== null) {
        Cookies.set(Cookie.AUTH_TOKEN, authenticationToken);
      }
      if (refreshToken !== null) {
        Cookies.set(Cookie.REFRESH_TOKEN, refreshToken);
      }
    },
    SignIn() {
      axios
        .get(BaseConnectionString + "/api/login", {
          params: { username: "Benjamin" },
        })
        .then((response) => {
          self.SetTokens(response.data.authToken, response.data.refreshToken);
          getParent(self).viewStore.openHomePage();
          getParent(self).LoadData();
        });
    },
    ResfreshTokens() {
      return axios
        .post(
          BaseConnectionString + "/api/token/refresh",
          {
            Token: self.AuthenticationToken,
            RefreshToken: self.RefreshToken,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          self.SetTokens(response.data.token, response.data.refreshToken);
        });
    },
    GetTokensFromCookiesAndRefresh() {
      var authToken = Cookies.get(Cookie.AUTH_TOKEN);
      var refreshToken = Cookies.get(Cookie.REFRESH_TOKEN);
      if (authToken !== undefined && refreshToken !== undefined) {
        axios
        .post(
          BaseConnectionString + "/api/token/refresh",
          {
            Token: authToken,
            RefreshToken: refreshToken,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          self.SetTokens(response.data.token, response.data.refreshToken);
          getParent(self).LoadData();
          getParent(self).viewStore.openHomePage();
        })
        .catch((error) => {
          console.log(error);
          self.SetTokens(null, null)
          Cookies.remove(Cookie.AUTH_TOKEN);
          Cookies.remove(Cookie.REFRESH_TOKEN);
        });
      }
    },
    SignOut() {
      self.SetTokens(null, null)
      Cookies.remove(Cookie.AUTH_TOKEN);
      Cookies.remove(Cookie.REFRESH_TOKEN);
      getParent(self).viewStore.openHomePage();
    }
  }))
  .views((self) => ({
    get isAuthenticated() {
      return self.AuthenticationToken != null && self.RefreshToken != null;
    },
  }));

export default TokenStore;
