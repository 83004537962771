import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { observer } from "mobx-react";
import { values } from "mobx";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import ViewProject from "./ViewProject";

const MyProjects = (props) => {
  const { Projects } = props.applicationStore.projectStore;
  {
    return (
      <div className="MyProjects">
        <List component="nav">
          {values(Projects).map((project) => {
            var { taskId, title } = project;
            return (
              <ViewProject key={project.id} project={project}>
                <ListItem
                  key={project.id}
                  onClick={() => console.log("ddd")}
                >
                  <ListItemText primary={title} />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={project.status != 0}
                      onClick={() => project.ToggleComplete()}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </ViewProject>
            );
          })}
        </List>
      </div>
    );
  }
};

export default observer(MyProjects);
