import Locale, {
  ExploreTasksPage,
  HomePage,
  MenuLocale,
  MyTasksPage,
  NewTaskForm,
  SettingsPage,
  Task,
} from "../../Models/Locale";

export const enUS = Locale.create({
  locale: "en-US",
  name: "English",
  teacherPortal: "Teacher Portal",
  menu: MenuLocale.create({
    newTask: "New Task",
    myTasks: "My Tasks",
    exploreTasks: "Explore Tasks",
    myProjects: "My Projects",
    home: "Home",
    signOut: "Sign Out",
  }),
  newTaskForm: NewTaskForm.create({
    language: "Language",
    title: "Title",
    description: "Description",
    themes: "Themes",
    subjects: "Subjects",
    ageRange: "Age Range",
    timeRange: "Time",
    teacherDescriptionTitle: "Learning objectives",
    teacherDescriptionPlaceholder:
      "Here you can write notes for the teacher in class",
    studentDescriptionTitle: "Task",
    studentDescriptionPlaceholder:
      "Here you can write the task for the students",
    saveButtonTooltip: "Save",
    previewButtonTooltip: "Preview",
    clearButtonTooltip: "Clear form",
    backButtonTooltip: "Back",
    titleValidationErrorMessage: "An error occured: A title is required",
  }),
  homePage: HomePage.create({
    title: "Ludenso Create Teacher Portal",
    aboutLudensoCreateTitle: "What is Ludenso Create?",
    aboutLudensoCreateDescription:
      "Ludenso Create allows kids around the world to make their dreams come to life through 3D and AR",
    navigateToLudensoCreateButtonText: "Try it out!",
    aboutLudensoCreateTeacherPortalTitle: "What is the Teacher Portal?",
    aboutLudensoCreateTeacherPortalDescription:
      "The Ludenso Create Teacher Portal is made for teachers to make and find pedagogical resources",
    featuredTaskTitle: "Example task",
  }),
  exploreTasksPage: ExploreTasksPage.create({
    title: "Explore tasks",
    filterOnAge: "Filter on age:",
    filterOnSubject: "Filter on subjects:",
    filterOnThemes: "Filter on themes:",
    estimatedtime: "Estimated time",
    hours: "hours",
    years: "years",
    printButtonTooltip: "Print",
    backButtonTooltip: "Back",
    teacherViewButtonTooltip: "Teacher friendly",
    studentViewButtonTooltip: "Student friendly",
    makeVariantButtonTooltip: "Make variant of task",
  }),
  myTasksPage: MyTasksPage.create({
    title: "My tasks",
    editButtonTooltip: "Edit task",
    printButtonTooltip: "Print",
    backButtonTooltip: "Back",
    teacherViewButtonTooltip: "Teacher friendly",
    studentViewButtonTooltip: "Student friendly",
  }),
  settingsPage: SettingsPage.create({
    title: "Settings",
    selectLanguage: "Set language",
  }),
  task: Task.create({
    availableAgeRanges: [
      [6, 7],
      [8, 9],
      [10, 12],
      [13, 15],
      [16, 18],
    ],
    availableSubjects: [
      "Mathematics",
      "Science",
      "Language",
      "Arts and design",
      "History",
      "Social science",
      "Geography",
      "Business",
      "Religion",
    ],
    availableThemes: [
      "Sustainability",
      "Democracy",
      "Citizenship",
      "Technology",
      "Entrepreneurship",
      "Collaboration",
      "Communication",
      "Critical thinking",
      "21st century skills",
    ],
  }),
});
