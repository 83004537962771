import axios from "axios";
import Cookies from "js-cookie";
import { BaseConnectionString } from "./ConnectionStrings";
import { Cookie } from "./Cookie";

export const RefreshTokenInterceptor = (failedRequest) =>
  axios
    .post(
      BaseConnectionString + "/api/token/refresh",
      {
        Token: Cookies.get(Cookie.AUTH_TOKEN),
        RefreshToken: Cookies.get(Cookie.REFRESH_TOKEN),
      },
      { headers: { "Content-Type": "application/json" } }
    )
    .catch(function (error) {
      console.log(error);
    })
    .then((tokenRefreshResponse) => {
      console.log("Intercepting");
      Cookies.set(Cookie.AUTH_TOKEN, tokenRefreshResponse.data.token);
      Cookies.set(Cookie.REFRESH_TOKEN, tokenRefreshResponse.data.refreshToken);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    });
