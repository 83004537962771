import React from "react";
import { observer } from "mobx-react";
import LocalizationStore from "../../Stores/LocalizationStore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemText } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const SettingsPage = (props) => {
  {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <div style={{ maxWidth: "800px" }}>
        <Typography variant="h4" gutterBottom>
          {LocalizationStore.selectedLocale.settingsPage.title}
        </Typography>
        <div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>
                {LocalizationStore.selectedLocale.settingsPage.selectLanguage}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {LocalizationStore.selectedLocale.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.root}>
                {LocalizationStore.locales.map((language) => {
                  return (
                    <ListItem
                      key={language.locale}
                      onClick={() => (language.locale ===
                        LocalizationStore.selectedLocale.locale) ? {} :
                        LocalizationStore.SetSelectedLocale(language, props.applicationStore)
                      }
                      selected={
                        language.locale ===
                        LocalizationStore.selectedLocale.locale
                      }
                    >
                      <ListItemText>{language.name}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
};

export default observer(SettingsPage);
