import axios from "axios";
import Cookies from "js-cookie";
import { BaseConnectionString } from "./ConnectionStrings";
import { Cookie } from "./Cookie";

const codeForFeide = "code-for-feide";
const codeForGoogle = "code-for-google";
const codeForFacebook = "code-for-facebook";
const codeForApple = "code-for-apple";
const codes = [codeForFeide, codeForGoogle, codeForFacebook, codeForApple];

export function AutheticateBycode(applicationStore) {
  var { tokenStore, viewStore } = applicationStore;
  var urlParams = new URLSearchParams(window.location.search);

  var isAutoLogin = Cookies.get(Cookie.STAY_LOGGED_IN);
  if (isAutoLogin !== undefined) {
    applicationStore.SetStayLoggedIn(true);
  } else {
    applicationStore.SetStayLoggedIn(false);
  }

  codes.forEach((code) => {
    if (urlParams.has(code)) {
      var authCode = urlParams.get(code);

      axios
        .get(BaseConnectionString + "/api/account/auth-by-code", {
          params: { code: authCode },
        })
        .then((response) => {
          tokenStore.SetTokens(
            response.data.authToken,
            response.data.refreshToken
          );
          applicationStore.LoadData();
          viewStore.openHomePage();
          return;
        });
    }
  });

  if (applicationStore.stayLoggedIn) {
    tokenStore.GetTokensFromCookiesAndRefresh();
  }
}
