import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { observer } from "mobx-react";
import LocalizationStore from "../../Stores/LocalizationStore";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
  },
}));

function AgeSelect(props) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {LocalizationStore.selectedLocale.newTaskForm.ageRange + ":"}
      </Typography>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <Select
          value={LocalizationStore.selectedLocale.task.availableAgeRanges
            .toJSON()
            .find((ageRange) => ageRange[0] == props.value[0])}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {LocalizationStore.selectedLocale.task.availableAgeRanges.map(
            (ageRange) => (
              <MenuItem key={ageRange} value={ageRange}>
                {ageRange[0] + " - " + ageRange[1]}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export default observer(AgeSelect);
