import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import rocket from "../../static/images/Rocket.png";
import { Button } from "@material-ui/core";
import LocalizationStore from "../../Stores/LocalizationStore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  details: {
    height: "273px",
  },
  content: {
    height: "-webkit-fill-available",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cover: {
    width: "210px",
    flexShrink: 0,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const AboutCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={rocket}
        title="Live from space album cover"
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="body1" color="textSecondary">
            {
              LocalizationStore.selectedLocale.homePage
                .aboutLudensoCreateDescription
            }
          </Typography>
          <div className={classes.controls}>
            <Button
              className={classes.margin}
              variant="contained"
              color="primary"
              onClick={() =>
                window.open("http://create.ludenso.no/3D", "_blank")
              }
            >
              {
                LocalizationStore.selectedLocale.homePage
                  .navigateToLudensoCreateButtonText
              }
            </Button>
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

export default observer(AboutCard);
