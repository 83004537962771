import { observer } from "mobx-react";
import React from "react";
import Editor from "rich-markdown-editor";

class MarkdownEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          padding: this.props.readOnly ? "0px" : "30px",
          background: "#ffffff",
        }}
      >
        <Editor
          readOnly={this.props.readOnly}
          defaultValue={this.props.value}
          dictionary={{newLineEmpty: ""}}
          placeholder={this.props.readOnly ? "" : this.props.placeholder}
          onChange={(e) => this.props.callback(e())}
          disableExtensions={[
            "code_inline",
            "strikethrough",
            "table",
            "container_notice",
            "image",
            "highlight",
            "blockquote",
            "embed",
            "code_block",
            "code_fence",
            "td",
            "th",
            "tr",
            "hr",
            "br",
            "checkbox_item",
            "checkbox_list"
          ]}
        />
      </div>
    );
  }
}

export default observer(MarkdownEditor);
