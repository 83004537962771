import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007CEF",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    subject: "#ff9900",
    theme: "#00A09E",
    age: "#00AF70",
    time: "#E20067",
  },
  overrides: {
    MuiFormControl: {
      root: {
        background: "#ffffff",
      },
      fullWidth: {
        background: "#ffffff",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: "5px",
        margin: 8,
        color: "#ffffff",
      },
    },
    MuiTouchRipple: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "22pt",
        fontWeight: "bold",
      },
      h5: {
        fontSize: "16pt",
        fontWeight: "bold",
      },
      h6: {
        fontSize: "14pt",
        fontWeight: "bold",
      },
      colorTextSecondary: {
        color: "#02192E"
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: "18pt",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 4px 8px 0px rgb(0 0 0 / 20%)",
      },
    },
  },
});

export default theme;
