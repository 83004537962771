import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import SelectChip from "../Primitives/SelectChip";
import LocalizationStore from "../../Stores/LocalizationStore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function SubjectsSelect(props) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {LocalizationStore.selectedLocale.newTaskForm.subjects + ":"}
      </Typography>
      <FormControl
        size="small"
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          variant="outlined"
          multiple
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <SelectChip key={value} name={value} type="subject" />
              ))}
            </div>
          )}
        >
          {LocalizationStore.selectedLocale.task.availableSubjects.map(
            (name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export default observer(SubjectsSelect);
