import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import LocalizationStore from "../../Stores/LocalizationStore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
  },
}));

const timeRanges = [
  [1, 2],
  [3, 5],
  [6, 10],
  [11, 15],
  [16, 20],
];

function TimeRangeSelect(props) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {LocalizationStore.selectedLocale.newTaskForm.timeRange + ":"}
      </Typography>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <Select
          value={timeRanges.find(
            (x) => x[0] == props.value[0] && x[1] == props.value[1]
          )}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {timeRanges.map((timeRange) => (
            <MenuItem key={timeRange} value={timeRange}>
              {timeRange[0] + " - " + timeRange[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default observer(TimeRangeSelect);
