import {
  destroy,
  detach,
  getParent,
  getSnapshot,
  types,
} from "mobx-state-tree";
import { Task } from "../Models/Task";
import axios from "axios";
import { BaseConnectionString } from "../utils/ConnectionStrings";
import { Cookie } from "../utils/Cookie";
import Cookies from "js-cookie";
import LocalizationStore from "./LocalizationStore";

const MyTasksStore = types
  .model("MyTasksStore", {
    selectedTaskId: types.maybeNull(types.integer),
    tasks: types.array(Task),
    teacherMode: types.boolean,
  })
  .views((self) => ({
    get SelectedLocaleTasks() {
      return self.tasks.filter(
        (task) => task.cultureName === LocalizationStore.selectedLocale.locale
      );
    },
    get SelectedTask() {
      var selectedTask = self.tasks.find(
        (task) => task.id === self.selectedTaskId
      );
      return selectedTask;
    },
  }))
  .actions((self) => ({
    LoadTasks() {
      axios
        .get(BaseConnectionString + "/api/gettask/my", {
          headers: {
            Authorization: "Bearer " + Cookies.get(Cookie.AUTH_TOKEN),
          },
        })
        .then((response) => {
          var tasks = response.data;
          tasks.forEach((task) => {
            task.themes = task.themes.map((theme) => theme.title);
            task.subjects = task.subjects.map((subject) => subject.title);
          });

          var taskModels = tasks.map((taskResponse) =>
            Task.create(taskResponse)
          );
          self.SetTasks(taskModels);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    IsMyTask(taskId) {
      return self.tasks.map((task) => task.id).includes(taskId);
    },
    ToggleTeacherMode() {
      self.teacherMode = !self.teacherMode;
    },
    SetSelectedTask(taskId) {
      self.selectedTaskId = taskId;
      self.teacherMode = true;
    },
    SetTasks(tasks) {
      self.tasks.replace(tasks);
    },
    AddTask(task) {
      self.tasks.push(task);
    },
    GetTaskById(taskId) {
      return self.tasks.find((element) => element.id == taskId);
    },
    EditTask(task) {
      var applicationStore = getParent(self);
      var snapShot = getSnapshot(task);
      applicationStore.newTaskStore.InitializeWithTask(snapShot);
      applicationStore.viewStore.openNewTaskPage();
      self.SetSelectedTask(null);
    },
    Remove(task) {
      detach(task);
    },
    removeTaskWithId(taskId) {
      var task = self.tasks.find((task) => task.id === taskId);
      destroy(task);
    },
    RemoveAllTasksFromStore() {
      self.tasks = [];
    },
  }));

export default MyTasksStore;
