import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import Cookies from "js-cookie";
import React from "react";
import { Cookie } from "../../utils/Cookie";

export default function CookiesConsent() {
  const [open, setOpen] = React.useState(
    Cookies.get(Cookie.COOKIES_ACCEPTED) === undefined
  );

  const handleClose = () => {
    setOpen(false);
    Cookies.set(Cookie.COOKIES_ACCEPTED, "true");
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{"This website uses cookies"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
        This site uses cookies. You can continue to use the site as usual if you accept this. Read more in our cookies policy.
        </DialogContentText>
        <Link href="https://ludenso.com/cookie-policy" target="blank">Cookies policy</Link>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Yes, I accept cookies
        </Button>
      </DialogActions>
    </Dialog>
  );
}
