import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


function TextfieldInput(props) {
    var {title, value, onChange, error} = props;
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <TextField
        fullWidth
          multiline
          variant="outlined"
          value={value}
          onChange={event => onChange(event.target.value)}
          error={error}
        />
    </div>
  );
}

export default observer(TextfieldInput);