import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { Switch } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const pageStyle = `
@page {
  size: 190mm 270mm;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}
`;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ViewProject = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { project } = props;

  var subjects = "Subjects: ";
  //project.task.subjects.map((subject) => (subjects += " " + subject));

  var themes = "Themes: ";
  //project.task.themes.map((theme) => (themes += " " + theme));

  var componentRef;

  return (
    <div>
      <div onClick={handleClickOpen}>{props.children}</div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {project.title}
        </DialogTitle>
        <DialogContent dividers>
          Complete
          <Switch
            edge="end"
            checked={project.isComplete != 0}
            onClick={() => project.ToggleComplete()}
          />
        </DialogContent>
        {/*
        <DialogContent dividers>{project.task.taskTitle}</DialogContent>
        <DialogContent dividers>{themes}</DialogContent>
        <DialogContent dividers>{subjects}</DialogContent>
        */}
        <DialogContent dividers>
          <div ref={(el) => (componentRef = el)}>
            {/*<DraftEditorReadOnly task={project.task} />*/}
          </div>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PrintIcon />}
              >
                Print / Save
              </Button>
            )}
            content={() => componentRef}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(ViewProject);
