import { types } from "mobx-state-tree";
import MyTasksStore from "./MyTasksStore";
import NewTaskStore from "./NewTaskStore";
import ProjectStore from "./ProjectStore";
import { TaskStore } from "./TaskStore";
import TokenStore from "./TokenStore";
import { ViewStore } from "./ViewStore";
import React from "react";
import { Task } from "../Models/Task";
import LocalizationStore from "./LocalizationStore";
import { Cookie } from "../utils/Cookie";
import Cookies from "js-cookie";

export const ApplicationStore = types
  .model("ApplicationStore", {
    viewStore: ViewStore,
    taskStore: TaskStore,
    newTaskStore: NewTaskStore,
    projectStore: ProjectStore,
    myTasksStore: MyTasksStore,
    tokenStore: TokenStore,
    stayLoggedIn: types.boolean
  })
  .actions((self) => ({
    LoadData() {
      self.taskStore.LoadTasks();
      self.myTasksStore.LoadTasks();
    },
    ClearData() {
      self.taskStore.RemoveAllTasksFromStore();
      self.myTasksStore.RemoveAllTasksFromStore();
      self.newTaskStore.Clear();
    },
    SignOut() {
      self.ClearData();
      self.tokenStore.SignOut();
    },
    SetStayLoggedIn(stayLoggedIn) {
      self.stayLoggedIn = stayLoggedIn;
      if (stayLoggedIn) {
        Cookies.set(Cookie.STAY_LOGGED_IN, "true");
      } else {
        Cookies.remove(Cookie.STAY_LOGGED_IN);
      }
    }
  }))
  .create({
    viewStore: ViewStore.create(),
    taskStore: TaskStore.create({
      isLoadingTasks: false,
      tasks: [],
      subjectFilters: [],
      themeFilters: [],
      ageRangefilters: [],
      selectedTask: null,
      teacherMode: true
    }),
    newTaskStore: NewTaskStore.create({
      task: Task.create({
        description: "",
        title: "",
        subtitle: "",
        minAge: 6,
        maxAge: 7,
        themes: [],
        subjects: [],
        minTime: "01:00:00",
        maxTime: "02:00:00",
        teacherDescription: "",
        cultureName: LocalizationStore.selectedLocale.locale,
      }),
      isPreviewMode: false,
      isValidationErrorPromptOpen: false
    }),
    projectStore: ProjectStore.create({
      Porjects: [],
    }),
    myTasksStore: MyTasksStore.create({
      tasks: [],
      teacherMode: true
    }),
    tokenStore: TokenStore.create({
      AuthenticationToken: null,
      RefreshToken: null,
    }),
    stayLoggedIn: false,
  });

export const ApplicationStoreContext = React.createContext("ApplicationStore");
