import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import LocalizationStore from "./Stores/LocalizationStore";
import HomeIcon from "@material-ui/icons/Home";
import { Settings } from "@material-ui/icons";
import { getParent } from "mobx-state-tree";
import { Pages } from "./Stores/ViewStore";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const menuItems = (viewStore) => (
  <div>
    <ListItem
      button
      onClick={viewStore.openHomePage}
      selected={viewStore.page === Pages.HOME}
    >
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText>{LocalizationStore.selectedLocale.menu.home}</ListItemText>
    </ListItem>
    <ListItem
      button
      onClick={viewStore.openNewTaskPage}
      selected={viewStore.page === Pages.NEW_TASK}
    >
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText>
        {LocalizationStore.selectedLocale.menu.newTask}
      </ListItemText>
    </ListItem>
    <ListItem
      button
      onClick={viewStore.openMyTasksPage}
      selected={viewStore.page === Pages.MY_TASKS}
    >
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText>
        {LocalizationStore.selectedLocale.menu.myTasks}
      </ListItemText>
    </ListItem>
    <ListItem
      button
      onClick={viewStore.openExploreTasksPage}
      selected={viewStore.page === Pages.EXPLORE_TASKS}
    >
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
      <ListItemText>
        {LocalizationStore.selectedLocale.menu.exploreTasks}
      </ListItemText>
    </ListItem>
    <ListItem
      button
      onClick={viewStore.openSettingsPage}
      selected={viewStore.page === Pages.SETTINGS}
    >
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <ListItemText>
        {LocalizationStore.selectedLocale.settingsPage.title}
      </ListItemText>
    </ListItem>
    <ListItem button onClick={getParent(viewStore).SignOut}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText>{LocalizationStore.selectedLocale.menu.signOut}</ListItemText>
    </ListItem>
  </div>
);
