import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import { BaseConnectionString } from "../../utils/ConnectionStrings";
import { ButtonBase, Checkbox, FormControlLabel } from "@material-ui/core";
import apple from "../../static/images/Apple.png";
import facebook from "../../static/images/Facebook.png";
import google from "../../static/images/Google.png";
import feide from "../../static/images/Feide.png";
import { observer } from "mobx-react";
import DevTools from "../Misc/DevTools";
import CookiesConsent from "../Primitives/CookiesConsent";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  image: {
    width: "296px",
    height: "48px",
  },
  formControlLabel: {
    width: "296px",
    height: "48px",
    margin: "0px",
  },
}));

const thirdPartyProviders = [
  {
    image: google,
    connectionStringExtension: "/api/account/signin-google?redirecturi=",
  },
  {
    image: facebook,
    connectionStringExtension: "/api/account/signin-facebook?redirecturi=",
  },
  {
    image: apple,
    connectionStringExtension: "/api/account/signin-apple?redirecturi=",
  },
  {
    image: feide,
    connectionStringExtension: "/api/account/signin-feide?redirecturi=",
  },
];

const SignInPage = ({ applicationStore }) => {
  const classes = useStyles();
  const origin = window.location.href;

  return (
    <div>
      <Container component="main" maxWidth="xs" id={"container"}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {thirdPartyProviders.map((provider) => (
            <FormControl variant="outlined" className={classes.formControl}>
              <ButtonBase
                variant="contained"
                color="primary"
                href={
                  BaseConnectionString +
                  provider.connectionStringExtension +
                  origin
                }
              >
                <img className={classes.image} src={provider.image} />
              </ButtonBase>
            </FormControl>
          ))}
          <FormControl variant="outlined" className={classes.formControl}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={applicationStore.stayLoggedIn}
                  onChange={() =>
                    applicationStore.SetStayLoggedIn(
                      !applicationStore.stayLoggedIn
                    )
                  }
                />
              }
              label="Stay signed in"
            />
          </FormControl>
        </div>
      {process.env.NODE_ENV === "development" ? (
        <DevTools applicationStore={applicationStore} />
      ) : undefined}
      </Container>      
      <CookiesConsent id={"cookieConsent"}/>
    </div>
  );
};

export default observer(SignInPage);
