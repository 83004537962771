import { types, destroy, getParent, getSnapshot } from "mobx-state-tree";
import { Task } from "../Models/Task";
import axios from "axios";
import { BaseConnectionString } from "../utils/ConnectionStrings";
import { Cookie } from "../utils/Cookie";
import Cookies from "js-cookie";
import LocalizationStore from "./LocalizationStore";

export const TaskStore = types
  .model("TaskStore", {
    selectedTaskId: types.maybeNull(types.integer),
    tasks: types.array(Task),
    isLoadingTasks: types.boolean,
    teacherMode: types.boolean,
    subjectFilters: types.array(types.string),
    themeFilters: types.array(types.string),
    ageRangeFilters: types.array(types.array(types.integer)),
  })
  .views((self) => ({
    get SelectedTask() {
      var selectedTask = self.tasks.find(
        (task) => task.id === self.selectedTaskId
      );
      return selectedTask;
    },
    get SelectedLocaleTasks() {
      return self.tasks.filter(
        (task) => task.cultureName === LocalizationStore.selectedLocale.locale
      );
    },
    get FilteredTasks() {
      return self.SelectedLocaleTasks.filter(
        (task) =>
          (task.subjects.some((subject) =>
            self.subjectFilters.includes(subject)
          ) ||
            self.subjectFilters.length === 0) &&
          (task.themes.some((theme) => self.themeFilters.includes(theme)) ||
            self.themeFilters.length === 0) &&
          (self.ageRangeFilters.some(
            (ageRangeFilter) => ageRangeFilter[0] === task.minAge
          ) ||
            self.ageRangeFilters.length === 0)
      );
    },
  }))
  .actions((self) => ({
    ClearFilters() {
      self.ageRangeFilters = [];
      self.subjectFilters = [];
      self.themeFilters = [];
    },
    ToggleTeacherMode() {
      self.teacherMode = !self.teacherMode;
    },
    SetSelectedTask(taskId) {
      self.selectedTaskId = taskId;
      self.teacherMode = true;
    },
    SetSubjectFilters(subjects) {
      self.subjectFilters = subjects;
    },
    SetThemeFilters(themes) {
      self.themeFilters = themes;
    },
    SetAgeRangeFilters(ageRanges) {
      self.ageRangeFilters = ageRanges;
    },
    AddOrRemoveSubjectFromtFilter(subject) {
      if (self.subjectFilters.includes(subject)) {
        self.subjectFilters.remove(subject);
      } else {
        self.subjectFilters.push(subject);
      }
    },
    AddOrRemoveThemeFromtFilter(theme) {
      if (self.themeFilters.includes(theme)) {
        self.themeFilters.remove(theme);
      } else {
        self.themeFilters.push(theme);
      }
    },
    AddOrRemoveAgeRangeFromtFilter(ageRange) {
      if (
        self.ageRangeFilters.some(
          (ageRangeFilter) => ageRangeFilter[0] === ageRange[0]
        )
      ) {
        self.ageRangeFilters = self.ageRangeFilters.filter(
          (ageRangeFilter) => ageRangeFilter[0] !== ageRange[0]
        );
      } else {
        self.ageRangeFilters.push(ageRange.toJSON());
      }
    },
    LoadTasks() {
      self.SetLoading(true);
      axios
        .get(BaseConnectionString + "/api/gettask/public", {
          headers: {
            Authorization: "Bearer " + Cookies.get(Cookie.AUTH_TOKEN),
          },
        })
        .then((response) => {
          var tasks = response.data;
          tasks.forEach((task) => {
            task.themes = task.themes.map((theme) => theme.title);
            task.subjects = task.subjects.map((subject) => subject.title);
          });
          var taskModels = tasks.map((taskResponse) =>
            Task.create(taskResponse)
          );
          self.SetLoading(false);
          self.SetTasks(taskModels);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    SetLoading(isLoading) {
      self.isLoadingTasks = isLoading;
    },
    SetTasks(tasks) {
      self.tasks.replace(tasks);
    },
    AddTask(task) {
      self.tasks.push(task);
    },
    MakeVariant() {
      var applicationStore = getParent(self);

      if (IsSelectedTaskMine) {
        applicationStore.myTasksStore.EditTask(
          applicationStore.myTasksStore.GetTaskById(self.selectedTaskId)
        );
      } else {
        var task = self.GetTaskById(self.selectedTaskId).GetClone();
        var snapshot = getSnapshot(task);
        applicationStore.newTaskStore.InitializeWithTask(snapshot);
        applicationStore.viewStore.openNewTaskPage();
        self.SetSelectedTask(null);
      }
    },
    GetTaskById(taskId) {
      return self.tasks.find((element) => element.id === taskId);
    },
    IsSelectedTaskMine() {
      return getParent(self)
        .myTasksStore.tasks.map((task) => task.id)
        .includes(self.selectedTaskId);
    },
    removeTaskWithId(taskId) {
      var task = self.tasks.find((task) => task.id === taskId);
      destroy(task);
    },
    RemoveAllTasksFromStore() {
      self.tasks = [];
    },
  }));
