import Locale, {
  ExploreTasksPage,
  HomePage,
  MenuLocale,
  MyTasksPage,
  NewTaskForm,
  SettingsPage,
  Task,
} from "../../Models/Locale";

export const noNO = Locale.create({
  locale: "no-NO",
  name: "Norsk (bokmål)",
  teacherPortal: "Lærerportal",
  menu: MenuLocale.create({
    newTask: "Ny Oppgave",
    myTasks: "Mine Oppgaver",
    exploreTasks: "Utforsk Oppgaver",
    myProjects: "Mine Prosjekter",
    home: "Hjem",
    signOut: "Logg ut",
  }),
  newTaskForm: NewTaskForm.create({
    language: "Språk",
    title: "Tittel",
    description: "Beskrivelse",
    themes: "Temaer",
    subjects: "Fag",
    ageRange: "Aldersgruppe",
    timeRange: "Tidsestimat",
    teacherDescriptionTitle: "Mål for undervisningen",
    teacherDescriptionPlaceholder:
      "Her kan du skrive mål for undervisningen, forberedelsesnotater og annet læreren bør vite",
    studentDescriptionTitle: "Oppgavetekst",
    studentDescriptionPlaceholder:
      "Her kan du skrive oppgaveteksten til elevene",
    saveButtonTooltip: "Lagre",
    previewButtonTooltip: "Forhåndsvisning",
    clearButtonTooltip: "Blankt skjema",
    backButtonTooltip: "Tilbake",
    titleValidationErrorMessage: "Det oppstod en feil: Oppgaven mangler tittel"
  }),
  homePage: HomePage.create({
    title: "Ludenso Create Lærerportal",
    aboutLudensoCreateTitle: "Hva er Ludenso Create?",
    aboutLudensoCreateDescription:
      "Ludenso Create lar barn verden rundt skape gjennom 3D og AR",
    navigateToLudensoCreateButtonText: "Prøv Ludenso Create!",
    aboutLudensoCreateTeacherPortalTitle: "Hva er lærerportalen?",
    aboutLudensoCreateTeacherPortalDescription:
      "Lærerportalen er laget for at lærere enkelt kan bli inspirert, finne og lage pedagogisk materiale til Ludenso Create som er skreddersydd de nye lærerplanmålene.",
    featuredTaskTitle: "Utvalgt oppgave",
  }),
  exploreTasksPage: ExploreTasksPage.create({
    title: "Finn oppgaver",
    filterOnAge: "Filtrer på alder:",
    filterOnSubject: "Filtrer på fag:",
    filterOnThemes: "Filtrer på tema:",
    estimatedtime: "Estimert tid",
    hours: "timer",
    years: "år",
    printButtonTooltip: "Skriv ut",
    backButtonTooltip: "Tilbake",
    teacherViewButtonTooltip: "Lærervennlig",
    studentViewButtonTooltip: "Elevvennlig",
    makeVariantButtonTooltip: "Lag din egen variant av oppgaven",
  }),
  myTasksPage: MyTasksPage.create({
    title: "Mine oppgaver",
    editButtonTooltip: "Rediger oppgave",
    printButtonTooltip: "Skriv ut",
    backButtonTooltip: "Tilbake",
    teacherViewButtonTooltip: "Lærervennlig",
    studentViewButtonTooltip: "Elevvennlig",
  }),
  settingsPage: SettingsPage.create({
    title: "Innstillinger",
    selectLanguage: "Velg språk",
  }),
  task: Task.create({
    availableAgeRanges: [
      [6, 7],
      [8, 9],
      [10, 12],
      [13, 15],
      [16, 18],
    ],
    availableSubjects: [
      "Matematikk",
      "Naturfag",
      "Norsk",
      "Kunst og håndverk",
      "Historie",
      "Samfunnsfag",
      "Geografi",
      "Entreprenørskap",
      "KRLE",
    ],
    availableThemes: [
      "Bærekraftig utvikling",
      "Demokrati og medborgerskap",
      "Folkehelse og livsmestring",
      "Teknologi",
      "Entreprenørskap",
      "Samarbeid",
      "Kommunikasjon",
      "Kritisk tenkning",
      "21st century skills",
    ],
  }),
});
