import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import SelectChip from "../Primitives/SelectChip";
import LocalizationStore from "../../Stores/LocalizationStore";

const useStyles = makeStyles(() => ({
  root: {
    width: 260,
    minHeight: 300,
    height: "auto"
  },
  title: {
    overflowWrap: "anywhere"
  }
}));

export default function TaskCard({ task, onClick }) {
  const classes = useStyles();
  return (
    <div onClick={onClick}>
      <Card className={classes.root}>
        <CardHeader className={classes.title} title={task.title} />
        <CardContent>
          <Typography variant="subtitle2">
            {task.minAge +
              " - " +
              task.maxAge +
              " " +
              LocalizationStore.selectedLocale.exploreTasksPage.years}
          </Typography>
          <Typography variant="subtitle2">
            {LocalizationStore.selectedLocale.exploreTasksPage.estimatedtime +
              ": " +
              task.formattedMinTime +
              " - " +
              task.formattedMaxTime +
              " " +
              LocalizationStore.selectedLocale.exploreTasksPage.hours}
          </Typography>
          <div style={{ margin: "0 -8px" }}>
            {task.themes.map((theme) => (
              <SelectChip key={theme} name={theme} type={"theme"} />
            ))}
          </div>
          <div style={{ margin: "0 -8px" }}>
            {task.subjects.map((subject) => (
              <SelectChip key={subject} name={subject} type={"subject"} />
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
