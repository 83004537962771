import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import LocalizationStore from "../../Stores/LocalizationStore";
import MarkdownEditor from "./MarkdownEditor";
import Grid from "@material-ui/core/Grid";
import theme from "../../theme";
import { values } from "mobx";
import SelectChip from "../Primitives/SelectChip";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "20px",
  },
}));
const ViewTask = ({ task, teacherMode }) => {
  const classes = useStyles(theme);

  return (
    <div style={{ width: "190mm" }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        className={classes.title}
      >
        {task.title === null ? "" : task.title}
      </Typography>
      <Typography variant="h6" gutterBottom align="center">
        {" "}
      </Typography>
      <Grid container spacing={3} justify="space-evenly">
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            {LocalizationStore.selectedLocale.newTaskForm.ageRange +
              ": " +
              task.ageRange[0] +
              " - " +
              task.ageRange[1] +
              " " +
              LocalizationStore.selectedLocale.exploreTasksPage.years}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            {LocalizationStore.selectedLocale.newTaskForm.timeRange +
              ": " +
              task.timeRange[0] +
              " - " +
              task.timeRange[1] +
              " " +
              LocalizationStore.selectedLocale.exploreTasksPage.hours}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {LocalizationStore.selectedLocale.newTaskForm.description + ":"}
          </Typography>
          <Typography>{task.subTitle === null ? "" : task.subTitle}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">
            {LocalizationStore.selectedLocale.newTaskForm.themes + ":"}
          </Typography>
          {values(task.themes).map((theme) => (
            <SelectChip key={theme} name={theme} type="theme" />
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">
            {LocalizationStore.selectedLocale.newTaskForm.subjects + ":"}
          </Typography>
          {values(task.subjects).map((subject) => (
            <SelectChip key={subject} name={subject} type="subject" />
          ))}
        </Grid>
        {teacherMode ? 
        <Grid item xs={12}>
          <div>
            <Typography variant="h4" gutterBottom>
              {LocalizationStore.selectedLocale.newTaskForm.teacherDescriptionTitle}
            </Typography>
            <MarkdownEditor
              readOnly={true}
              key={task.title}
              value={task.teacherDescription}
            />
          </div>
        </Grid> : undefined}
        <Grid item xs={12}>
          <div>
            <Typography variant="h4" gutterBottom>
              {LocalizationStore.selectedLocale.newTaskForm.studentDescriptionTitle}
            </Typography>
            <MarkdownEditor
              readOnly={true}
              key={task.title}
              value={task.description}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(ViewTask);
