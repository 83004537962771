import { Chip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subject: {
    backgroundColor: theme.palette.subject,
    "&:focus": {
      backgroundColor: theme.palette.subject,
    },
    "&:active": {
      backgroundColor: theme.palette.subject,
    },
    "&:hover": {
      backgroundColor: theme.palette.subject,
    },
  },
  theme: {
    backgroundColor: theme.palette.theme,
    "&:focus": {
      backgroundColor: theme.palette.theme,
    },
    "&:active": {
      backgroundColor: theme.palette.theme,
    },
    "&:hover": {
      backgroundColor: theme.palette.theme,
    },
  },
  age: {
    backgroundColor: theme.palette.age,
    "&:focus": {
      backgroundColor: theme.palette.age,
    },
    "&:active": {
      backgroundColor: theme.palette.age,
    },
    "&:hover": {
      backgroundColor: theme.palette.age,
    },
  },
  time: {
    backgroundColor: theme.palette.time,
    "&:focus": {
      backgroundColor: theme.palette.time,
    },
    "&:active": {
      backgroundColor: theme.palette.time,
    },
    "&:hover": {
      backgroundColor: theme.palette.time,
    },
  },
}));

export default function SelectChip(props) {
  const className = useStyles()[props.type];
  return (
    <Chip
      key={props.name}
      label={props.name}
      className={className}
      onDelete={props.onDelete}
      clickable={props.onClick ? true : false}
      onClick={props.onClick}
    />
  );
}
