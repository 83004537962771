import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import { Collapse, Snackbar } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArrowBack, Visibility } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ViewTask from "../Misc/ViewTask";
import EditTaskForm from "../Misc/EditTaskForm";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Tooltip from "@material-ui/core/Tooltip";
import LocalizationStore from "../../Stores/LocalizationStore";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function EditTaskPage(props) {
  var { newTaskStore } = props.applicationStore;
  var { task } = newTaskStore;

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={newTaskStore.isValidationErrorPromptOpen}
        autoHideDuration={3000}
        onClose={newTaskStore.CloseValidationErrorPrompt}
      >
        <Alert
          onClose={newTaskStore.CloseValidationErrorPrompt}
          severity="error"
        >
          {
            LocalizationStore.selectedLocale.newTaskForm
              .titleValidationErrorMessage
          }
        </Alert>
      </Snackbar>
      <Collapse in={!newTaskStore.isPreviewMode} collapsedHeight={0}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <Tooltip
              title={
                LocalizationStore.selectedLocale.newTaskForm.saveButtonTooltip
              }
              placement="bottom"
            >
              <IconButton onClick={newTaskStore.SaveTask}>
                <SaveIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                LocalizationStore.selectedLocale.newTaskForm
                  .previewButtonTooltip
              }
              placement="bottom"
            >
              <IconButton onClick={newTaskStore.TogglePreviewMode}>
                <Visibility fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                LocalizationStore.selectedLocale.newTaskForm.clearButtonTooltip
              }
              placement="bottom"
            >
              <IconButton onClick={newTaskStore.Clear}>
                <InsertDriveFileIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>
          <EditTaskForm task={task} />
        </div>
      </Collapse>
      <Collapse
        key={newTaskStore.isPreviewMode}
        in={newTaskStore.isPreviewMode}
        collapsedHeight={0}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <Tooltip
              title={
                LocalizationStore.selectedLocale.newTaskForm.backButtonTooltip
              }
              placement="bottom"
            >
              <IconButton onClick={newTaskStore.TogglePreviewMode}>
                <ArrowBack fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ padding: "20mm 10mm 20mm 10mm", background: "white" }}>
            <ViewTask task={newTaskStore.task} />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default observer(EditTaskPage);
