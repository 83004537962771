import { types, getSnapshot, getParent } from "mobx-state-tree";
import { BaseConnectionString } from "../utils/ConnectionStrings";
import { Task } from "../Models/Task";
import axios from "axios";
import { Cookie } from "../utils/Cookie";
import Cookies from "js-cookie";
import LocalizationStore from "./LocalizationStore";

function GetDefaultTask() {
  return Task.create({
    description: "",
    title: "",
    subtitle: "",
    minAge: 6,
    maxAge: 7,
    themes: [],
    subjects: [],
    minTime: "01:00:00",
    maxTime: "02:00:00",
    teacherDescription: "",
    cultureName: LocalizationStore.selectedLocale.locale,
  });
}

const NewTaskStore = types
  .model("NewTaskStore", {
    task: Task,
    isPreviewMode: types.boolean,
    isValidationErrorPromptOpen: types.boolean,
  })
  .views((self) => ({
    IsFormValid() {
      return self.task.title !== null && self.task.title !== "";
    }
  }))
  .actions((self) => ({
    CloseValidationErrorPrompt() {
      self.isValidationErrorPromptOpen = false;
    },
    OpenValidationErrorPrompt() {
      self.isValidationErrorPromptOpen = true;
    },
    Clear() {
      self.task = GetDefaultTask();
    },
    TogglePreviewMode() {
      self.isPreviewMode = !self.isPreviewMode;
    },
    InitializeWithTask(task) {
      self.task = task;
    },
    SaveTask() {
      var snapShot = { ...getSnapshot(self.task) };
      snapShot.cultureName = LocalizationStore.selectedLocale.locale;
      if(!self.IsFormValid()) {
        self.OpenValidationErrorPrompt();
        return;
      }
      if (snapShot.id != undefined) {
        axios
          .put(BaseConnectionString + "/api/updatetask", snapShot, {
            headers: {
              Authorization: "Bearer " + Cookies.get(Cookie.AUTH_TOKEN),
            },
          })
          .then((response) => {
            var task = response.data;
            task.themes = (task.themes !== null) ? task.themes.map((theme) => theme.title) : [];
            task.subjects = (task.subjects !== null) ? task.subjects.map((subject) => subject.title) : [];
            
            getParent(self).myTasksStore.removeTaskWithId(self.task.id);
            getParent(self).taskStore.removeTaskWithId(self.task.id);

            getParent(self).taskStore.AddTask(Task.create(task));
            getParent(self).myTasksStore.AddTask(Task.create(task));

            this.Clear();

            getParent(self).myTasksStore.SetSelectedTask(task.id);
            getParent(self).viewStore.openMyTasksPage();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(BaseConnectionString + "/api/createtask", snapShot, {
            headers: {
              Authorization: "Bearer " + Cookies.get(Cookie.AUTH_TOKEN),
            },
          })
          .then((response) => {
            var task = response.data;
            task.themes = (task.themes !== null) ? task.themes.map((theme) => theme.title) : [];
            task.subjects = (task.subjects !== null) ? task.subjects.map((subject) => subject.title) : [];
            
            getParent(self).taskStore.AddTask(Task.create(task));
            getParent(self).myTasksStore.AddTask(Task.create(task));
            
            this.Clear();
            
            getParent(self).myTasksStore.SetSelectedTask(task.id);
            getParent(self).viewStore.openMyTasksPage();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  }));

export default NewTaskStore;
