import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import theme from "./theme";
import createRouter from "./utils/router";
import { reaction } from "mobx";
import { AutheticateBycode } from "./utils/autheticator";
import {
  ApplicationStore,
  ApplicationStoreContext,
} from "./Stores/ApplicationStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from "axios";
import { RefreshTokenInterceptor } from "./utils/HttpClient";
import LocalizationStore from "./Stores/LocalizationStore";
import Cookies from "js-cookie";
import { Cookie } from "./utils/Cookie";

createAuthRefreshInterceptor(axios, RefreshTokenInterceptor);

const applicationStore = ApplicationStore;

const router = createRouter({
  "/newtask": applicationStore.viewStore.openNewTaskPage,
  "/mytasks": applicationStore.viewStore.openMyTasksPage,
  "/exploretasks": applicationStore.viewStore.openExploreTasksPage,
  "/myprojects": applicationStore.viewStore.openMyProjectsPage,
  "/home": applicationStore.viewStore.openHomePage,
});

window.onpopstate = function historyChange(ev) {
  if (ev.type === "popstate") {
    router(window.location.pathname);
  }
};

router(window.location.pathname);

AutheticateBycode(applicationStore);

var locale = Cookies.get(Cookie.LOCALE);
if (locale !== undefined) {
  LocalizationStore.SetLocaleById(locale);
}

ReactDOM.render(
  <ApplicationStoreContext.Provider value={applicationStore}>
    <ThemeProvider theme={theme}>
      <App applicationStore={applicationStore} />
    </ThemeProvider>
  </ApplicationStoreContext.Provider>,
  document.querySelector("#root")
);

reaction(
  () => applicationStore.viewStore.currentUrl,
  (path) => {
    if (window.location.pathname !== path) {
      window.history.pushState(null, null, path);
    }
  }
);
