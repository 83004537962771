import React from "react";
import { Typography } from "@material-ui/core";
import ThemeSelect from "../Selects/ThemeSelect";
import { observer } from "mobx-react";
import SubjectsSelect from "../Selects/SubjectsMultiSelect";
import AgeSelect from "../Selects/AgeSelect";
import TimeRangeSelect from "../Selects/TimeRangeSelect";
import LocalizationStore from "../../Stores/LocalizationStore";
import MarkdownEditor from "./MarkdownEditor";
import Grid from "@material-ui/core/Grid";
import TextFieldInput from "../Inputs/TextFieldInput";
import { values } from "mobx";
import { getNodeId } from "mobx-state-tree";

function EditTaskForm({ task }) {
  return (
    <div style={{ padding: "10mm 10mm 10mm 10mm" }}>
      <div style={{ width: "190mm" }}>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12}>
            <TextFieldInput
              title={LocalizationStore.selectedLocale.newTaskForm.title + ":"}
              value={task.title === null ? "" : task.title}
              onChange={(value) => task.SetTitle(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AgeSelect onChange={task.SetAgeRange} value={task.ageRange} />
          </Grid>
          <Grid item xs={6}>
            <TimeRangeSelect
              onChange={task.SetTimeRage}
              value={task.timeRange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldInput
              title={
                LocalizationStore.selectedLocale.newTaskForm.description + ":"
              }
              value={task.subTitle === null ? "" : task.subTitle}
              onChange={(value) => task.SetSubtitle(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <ThemeSelect
              onChange={task.SetThemes}
              value={values(task.themes)}
            />
          </Grid>
          <Grid item xs={6}>
            <SubjectsSelect
              onChange={task.SetSubjects}
              value={values(task.subjects)}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h4" gutterBottom>
                {LocalizationStore.selectedLocale.newTaskForm
                  .teacherDescriptionTitle + ":"}
              </Typography>
              <MarkdownEditor
                key={getNodeId(task)}
                value={task.teacherDescription}
                callback={(e) => task.SetTeacherDescription(e)}
                readOnly={false}
                placeholder={LocalizationStore.selectedLocale.newTaskForm.teacherDescriptionPlaceholder}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h4" gutterBottom>
                {LocalizationStore.selectedLocale.newTaskForm
                  .studentDescriptionTitle + ":"}
              </Typography>
              <MarkdownEditor
                key={getNodeId(task)}
                value={task.description}
                callback={(e) => task.SetText(e)}
                readOnly={false}
                placeholder={LocalizationStore.selectedLocale.newTaskForm.studentDescriptionPlaceholder}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default observer(EditTaskForm);
