import React, { useRef } from "react";
import { observer } from "mobx-react";
import { Collapse, Tooltip, Typography } from "@material-ui/core";
import LocalizationStore from "../../Stores/LocalizationStore";
import Grid from "@material-ui/core/Grid";
import TaskCard from "../Misc/TaskCard";
import ViewTaskNew from "../Misc/ViewTask";
import { ArrowBack, Print, School } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useReactToPrint } from "react-to-print";
import EditIcon from "@material-ui/icons/Edit";

function MyTasksPage(props) {
  var taskStore = props.applicationStore.myTasksStore;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"20mm"} ${"10mm"} ${"20mm"} ${"10mm"} !important; }`;
  };

  return (
    <div>
      <Collapse in={taskStore.selectedTaskId === null} collapsedHeight={0}>
        <Typography variant="h4" gutterBottom>
          {LocalizationStore.selectedLocale.myTasksPage.title}
        </Typography>
        <Grid container spacing={3}>
          {taskStore.SelectedLocaleTasks.map((value, index) => {
            return (
              <Grid item key={index}>
                <TaskCard
                  task={value}
                  onClick={() => taskStore.SetSelectedTask(value.id)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Collapse>
      {taskStore.selectedTaskId !== null ? (
        <Collapse in={taskStore.selectedTaskId !== null} collapsedHeight={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              <Tooltip
                title={
                  LocalizationStore.selectedLocale.myTasksPage.backButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={() => taskStore.SetSelectedTask(null)}>
                  <ArrowBack fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  LocalizationStore.selectedLocale.myTasksPage
                    .printButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={handlePrint}>
                  <Print fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  LocalizationStore.selectedLocale.myTasksPage.editButtonTooltip
                }
                placement="bottom"
              >
                <IconButton
                  onClick={() => taskStore.EditTask(taskStore.SelectedTask)}
                >
                  <EditIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  taskStore.teacherMode ?
                  LocalizationStore.selectedLocale.myTasksPage
                    .studentViewButtonTooltip : 
                    LocalizationStore.selectedLocale.myTasksPage
                      .teacherViewButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={() => taskStore.ToggleTeacherMode()}>
                  <School fontSize="large" color={taskStore.teacherMode ? undefined : "primary"}/>
                </IconButton>
              </Tooltip>
            </div>
            <div
              style={{ padding: "20mm 10mm 20mm 10mm", background: "white" }}
            >
              <div ref={componentRef}>
                <style>{getPageMargins()}</style>
                <ViewTaskNew task={taskStore.SelectedTask} teacherMode={taskStore.teacherMode}/>
              </div>
            </div>
          </div>
        </Collapse>
      ) : undefined}
    </div>
  );
}

export default observer(MyTasksPage);
