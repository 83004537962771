import Locale, {
  ExploreTasksPage,
  HomePage,
  MenuLocale,
  MyTasksPage,
  NewTaskForm,
  SettingsPage,
  Task,
} from "../../Models/Locale";

// Lulesami

export const smjNO = Locale.create({
  locale: "smj-NO",
  name: "Julevsámegiellaj",
  teacherPortal: "Åhpadiddjeportálla",
  menu: MenuLocale.create({
    newTask: "Ådå dahkamus",
    myTasks: "Muv dahkamusá",
    exploreTasks: "Åtsåda dahkamusájt",
    myProjects: "Muv prosjevta",
    home: "Sijddaj",
    signOut: "Loggit ålgus",
  }),
  newTaskForm: NewTaskForm.create({
    language: "Giella",
    title: "Namma",
    description: "Tjielggidus",
    themes: "Tiemá",
    subjects: "Fáhka",
    ageRange: "Áldarjuogos",
    timeRange: "Ájggeárvustallam",
    teacherDescriptionTitle: "Ulmme åhpadusájn",
    teacherDescriptionPlaceholder:
      "Åhpadibme",
    studentDescriptionTitle: "Dahkamustjálos",
    studentDescriptionPlaceholder:
      "Dahkamus oahppijda",
    saveButtonTooltip: "Vuorkuda",
    previewButtonTooltip: "Åvddåvuosedibme",
    clearButtonTooltip: "Guoros sjiebmá",
    backButtonTooltip: "Maŋus",
    titleValidationErrorMessage: "Ittjij vuorbástuvá: Dahkamusán vájllu namma"
  }),
  homePage: HomePage.create({
    title: "Ludenco Create oahppoportálla",
    aboutLudensoCreateTitle: "Mij la Ludenso Create?",
    aboutLudensoCreateDescription:
      "Ludenso Create dibddá mánájt birra værálda hábbmit 3D ja AD baktu",
    navigateToLudensoCreateButtonText: "Gæhttjala Ludenso Create!",
    aboutLudensoCreateTeacherPortalTitle: "Mij la åhpadiddjeportálla?",
    aboutLudensoCreateTeacherPortalDescription:
      "Åhpadiddjeportálla l dagáduvvam åhpadiddjijt måvtåstuhtátjit, pedagogihkalasj tiemájt gávnatjit ja dagátjit Ludenso Creatij ma li hiebaduvvam daj ådå oahppoplána mihttomierijda.",
    featuredTaskTitle: "Válljiduvvam dahkamus",
  }),
  exploreTasksPage: ExploreTasksPage.create({
    title: "Finn Åtså dahkamusájt",
    filterOnAge: "Ierida álldara milta:",
    filterOnSubject: "Ierida fága milta:",
    filterOnThemes: "Ierida tiemá milta:",
    estimatedtime: "Man guhkev jáhkkep vihpá",
    hours: "tijma",
    years: "jahke",
    printButtonTooltip: "Tjáleda",
    backButtonTooltip: "Maŋus",
    teacherViewButtonTooltip: "Vuogas åhpadiddjáj",
    studentViewButtonTooltip: "Vuogas oahppijda",
    makeVariantButtonTooltip: "Dagá ietjat variántav dahkamusás",
  }),
  myTasksPage: MyTasksPage.create({
    title: "Muv dahkamusá",
    editButtonTooltip: "Divo dahkamusáv",
    printButtonTooltip: "Tjáleda",
    backButtonTooltip: "Maŋus ",
    teacherViewButtonTooltip: "Vuogas åhpadiddjáj",
    studentViewButtonTooltip: "Vuogas oahppijda",
  }),
  settingsPage: SettingsPage.create({
    title: "Hiebadime",
    selectLanguage: "Vállji gielav",
  }),
  task: Task.create({
    availableAgeRanges: [
      [6, 7],
      [8, 9],
      [10, 12],
      [13, 15],
      [16, 18],
    ],
    availableSubjects: [
      "Matematihkka",
      "Luondofáhka",
      "Dárogiella",
      "Dájdda- ja duodjefáhka",
      "Histåvrrå",
      "Sebrudakfáhka",
      "Geografijja",
      "Álgadibme",
      "ÅIEE"
    ],
    availableThemes: [
      "Guoddelis åvddånibme",
      "Demokratijja ja guojmmeviesátvuohta",
      "Álmmukvarresvuohta ja rijbbam",
      "Teknologijja",
      "Álgadibme",
      "Aktisasjbarggo",
      "Guládallam",
      "Lájttális ájádallam",
      "21st century skills"
    ],
  }),
});
