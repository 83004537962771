import { types } from "mobx-state-tree";

export const MenuLocale = types.model("MenuLocale", {
  newTask: types.string,
  myTasks: types.string,
  exploreTasks: types.string,
  myProjects: types.string,
  home: types.string,
  signOut: types.string
});

export const NewTaskForm = types.model("NewTaskForm", {
  language: types.string,
  title: types.string,
  description: types.string,
  themes: types.string,
  subjects: types.string,
  ageRange: types.string,
  timeRange: types.string,
  teacherDescriptionTitle: types.string,
  teacherDescriptionPlaceholder: types.string,
  studentDescriptionTitle: types.string,
  studentDescriptionPlaceholder: types.string,
  saveButtonTooltip: types.string,
  previewButtonTooltip: types.string,
  clearButtonTooltip: types.string,
  backButtonTooltip: types.string,
  titleValidationErrorMessage: types.string
});

export const HomePage = types.model("HomePage", {
  title: types.string,
  aboutLudensoCreateTitle: types.string,
  aboutLudensoCreateDescription: types.string,
  navigateToLudensoCreateButtonText: types.string,
  aboutLudensoCreateTeacherPortalTitle: types.string,
  aboutLudensoCreateTeacherPortalDescription: types.string,
  featuredTaskTitle: types.string,
});

export const ExploreTasksPage = types.model("ExploreTasksPage", {
  title: types.string,
  filterOnAge: types.string,
  filterOnSubject: types.string,
  filterOnThemes: types.string,
  estimatedtime: types.string,
  hours: types.string,
  years: types.string,
  printButtonTooltip: types.string,
  backButtonTooltip: types.string,
  teacherViewButtonTooltip: types.string,
  studentViewButtonTooltip: types.string,
  makeVariantButtonTooltip: types.string,
});

export const MyTasksPage = types.model("MyTasksPage", {
  title: types.string,
  editButtonTooltip: types.string,
  printButtonTooltip: types.string,
  backButtonTooltip: types.string,
  teacherViewButtonTooltip: types.string,
  studentViewButtonTooltip: types.string,
});

export const SettingsPage = types.model("SettingsPage", {
  title: types.string,
  selectLanguage: types.string
});

export const Task = types.model("Task", {
  availableSubjects: types.array(types.string),
  availableThemes: types.array(types.string),
  availableAgeRanges: types.array(types.array(types.integer)),
});

const Locale = types.model("Locale", {
  locale: types.identifier,
  name: types.string,
  teacherPortal: types.string,
  menu: MenuLocale,
  newTaskForm: NewTaskForm,
  homePage: HomePage,
  exploreTasksPage: ExploreTasksPage,
  myTasksPage: MyTasksPage,
  settingsPage: SettingsPage,
  task: Task,
});

export default Locale;
