import Cookies from "js-cookie";
import { getSnapshot, types } from "mobx-state-tree";
import Locale from "../Models/Locale";
import { Cookie } from "../utils/Cookie";
import { enUS } from "./AvailableLocales/en-US";
import { nnNO } from "./AvailableLocales/nn-NO";
import { noNO } from "./AvailableLocales/no-NO";
import { saveAs } from "file-saver";
import { seNO } from "./AvailableLocales/se-NO";
import { smaNO } from "./AvailableLocales/sma-NO";
import { smjNO } from "./AvailableLocales/smj-NO";

const LocalizationStore = types
  .model("LocalizationStore", {
    locales: types.array(Locale),
    selectedLocale: types.reference(Locale),
  })
  .actions((self) => ({
    SetSelectedLocale(locale, applicationStore) {
      applicationStore.newTaskStore.Clear();
      applicationStore.taskStore.ClearFilters();
      self.selectedLocale = locale;
      Cookies.set(Cookie.LOCALE, locale.locale);
    },
    SetLocaleById(localeId) {
      self.selectedLocale = self.locales.find(
        (locale) => locale.locale === localeId
      );
    },
    Serialize() {
      saveAs(
        new Blob([JSON.stringify(getSnapshot(self.locales), null, 4)], {
          type: "application/json",
          name: "locales.json",
        }),
        "locales.json"
      );
    },
  }))
  .create({
    locales: [enUS, noNO, nnNO, seNO, smaNO, smjNO],
    selectedLocale: noNO.locale,
  });

export default LocalizationStore;
