import React, { useState } from "react";
import { observer } from "mobx-react";
import LocalizationStore from "../../Stores/LocalizationStore";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import AboutCard from "../Misc/AboutCard";
import TeacherPortalCard from "../Misc/TeacherPortalCard";
import TaskCardLarge from "../Misc/TaskCardLarge";
import AutorenewIcon from "@material-ui/icons/Autorenew";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "30px",
  },
  padding: {
    padding: "5px",
  },
}));

const HomePage = ({ applicationStore }) => {
  const classes = useStyles();

  const [, updateState] = useState();

  var tasks = applicationStore.taskStore.SelectedLocaleTasks;

  var randomTask =
    tasks.length > 0 ? tasks[Math.floor(Math.random() * tasks.length)] : null;

  const setRandomTask = () => {
    updateState({}, []);
    randomTask = tasks[Math.floor(Math.random() * tasks.length)];
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          {LocalizationStore.selectedLocale.homePage.title}
        </Typography>
      </div>
      <div style={{ width: "210mm" }}>
        <div className={classes.margin}>
          <Typography variant="h5">
            {LocalizationStore.selectedLocale.homePage.aboutLudensoCreateTitle}
          </Typography>
          <AboutCard />
        </div>
        <div className={classes.margin}>
          <Typography variant="h5">
            {
              LocalizationStore.selectedLocale.homePage
                .aboutLudensoCreateTeacherPortalTitle
            }
          </Typography>
          <TeacherPortalCard applicationStore={applicationStore} />
        </div>
        {tasks.length > 0 ? (
          <div className={classes.margin}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5">
                {LocalizationStore.selectedLocale.homePage.featuredTaskTitle}
              </Typography>

              <IconButton onClick={setRandomTask} className={classes.padding}>
                <AutorenewIcon fontSize="small" />
              </IconButton>
            </div>
            <TaskCardLarge
              task={randomTask}
              onClick={() => {
                applicationStore.taskStore.SetSelectedTask(randomTask.id);
                applicationStore.viewStore.openExploreTasksPage();
              }}
            />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default observer(HomePage);
