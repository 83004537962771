import axios from "axios";
import { getParent, getSnapshot, types } from "mobx-state-tree";
import { BaseConnectionString } from "../utils/ConnectionStrings";

const Project = types
  .model("Project", {
    id: types.maybe(types.integer),
    title: types.string,
    taskId: types.integer,
    status: types.integer
  })
  .actions((self) => ({
    ToggleComplete() {
      self.status = 1;
      self.title = "this title is default"

      console.log(getParent(getParent(self)));
      var snapshot = getSnapshot(self);
      
      axios.put(
        BaseConnectionString + "/api/updateproject",
        snapshot,
        {headers: {'Authorization': "Bearer " + getParent(getParent(getParent(self))).tokenStore.AuthenticationToken}}
      )
      .then(e => console.log(e));
    },
  }));

export default Project;

