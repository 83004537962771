import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import LocalizationStore from "../../Stores/LocalizationStore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  content: {
    height: "-webkit-fill-available",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const TeacherPortalCard = ({ applicationStore }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="body1" color="textSecondary">
        {LocalizationStore.selectedLocale.homePage.aboutLudensoCreateTeacherPortalDescription}
        </Typography>
        <div className={classes.controls}>
          <Button
            className={classes.margin}
            variant="contained"
            color="primary"
            onClick={applicationStore.viewStore.openExploreTasksPage}
          >
            {LocalizationStore.selectedLocale.menu.exploreTasks}
          </Button>
          <Button
            className={classes.margin}
            variant="contained"
            color="primary"
            onClick={applicationStore.viewStore.openNewTaskPage}
          >
            {LocalizationStore.selectedLocale.menu.newTask}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default observer(TeacherPortalCard);
