import { getParent, types } from "mobx-state-tree";
import LocalizationStore from "./LocalizationStore";

export const Pages = {
  NEW_TASK: "newTask",
  MY_TASKS: "myTasks",
  EXPLORE_TASKS: "exploreTasks",
  MY_PROJECTS: "myProjects",
  HOME: "home",
  SIGNIN: "signin",
  SETTINGS: "settings"
};

export const ViewStore = types
  .model({
    page: "exploreTasks",
  })
  .views((self) => ({
    get currentUrl() {
      switch (self.page) {
        case Pages.NEW_TASK:
          return "/newtask";
        case Pages.MY_TASKS:
          return "/mytasks";
        case Pages.EXPLORE_TASKS:
          return "/exploretasks";
        case Pages.MY_PROJECTS:
          return "/myprojects";
        case Pages.SIGNIN:
          return "/";
        case Pages.HOME:
          return "/home";
        case Pages.SETTINGS:
          return "/settings";
        default:
          return "/404";
      }
    },
  }))
  .actions((self) => ({
    openNewTaskPage() {
      self.page = Pages.NEW_TASK;
      document.title = LocalizationStore.selectedLocale.newTaskForm.title;
    },
    openMyTasksPage() {
      self.page = Pages.MY_TASKS;
      document.title = LocalizationStore.selectedLocale.myTasksPage.title;
    },
    openExploreTasksPage() {
      self.page = Pages.EXPLORE_TASKS;
      document.title = LocalizationStore.selectedLocale.exploreTasksPage.title;
    },
    openMyProjectsPage() {
      self.page = Pages.MY_PROJECTS;
      document.title = LocalizationStore.selectedLocale.homePage.title;
    },
    openSigninPage() {
      self.page = Pages.SIGNIN;
      document.title = LocalizationStore.selectedLocale.homePage.title;
    },
    openHomePage() {
      self.page = Pages.HOME;
      document.title = LocalizationStore.selectedLocale.homePage.title;
    },
    openSettingsPage() {
      self.page = Pages.SETTINGS;
      document.title = LocalizationStore.selectedLocale.settingsPage.title;
    },
  }));
