import React, { useRef } from "react";
import { observer } from "mobx-react";
import { Collapse, Tooltip, Typography } from "@material-ui/core";
import SelectChip from "../Primitives/SelectChip";
import LocalizationStore from "../../Stores/LocalizationStore";
import Grid from "@material-ui/core/Grid";
import TaskCard from "../Misc/TaskCard";
import ViewTask from "../Misc/ViewTask";
import { ArrowBack, Print, School } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useReactToPrint } from "react-to-print";
import EditIcon from "@material-ui/icons/Edit";

function ExploreTasksPage(props) {
  var taskStore = props.applicationStore.taskStore;

  const toggleTaskView = (taskId) => {
    taskStore.SetSelectedTask(taskId);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: ${"20mm"} ${"10mm"} ${"20mm"} ${"10mm"} !important; }`;
  };

  return (
    <div>
      <Collapse in={taskStore.selectedTaskId === null} collapsedHeight={0}>
        <Typography variant="h4" gutterBottom>
          {LocalizationStore.selectedLocale.exploreTasksPage.title}
        </Typography>
        <Typography variant="h6">
          {LocalizationStore.selectedLocale.exploreTasksPage.filterOnAge}
        </Typography>
        <div style={{ margin: "0 -8px 25px" }}>
          {taskStore.ageRangeFilters.map((ageRange) => (
            <SelectChip
              key={ageRange[0]}
              name={
                ageRange[0] +
                " - " +
                ageRange[1] +
                " " +
                LocalizationStore.selectedLocale.exploreTasksPage.years
              }
              type={"age"}
              onDelete={() =>
                taskStore.AddOrRemoveAgeRangeFromtFilter(ageRange)
              }
            />
          ))}
          {LocalizationStore.selectedLocale.task.availableAgeRanges
            .filter(
              (availableAgeRange) =>
                !taskStore.ageRangeFilters.some(
                  (ageRangeFilter) => ageRangeFilter[0] === availableAgeRange[0]
                )
            )
            .map((ageRange) => (
              <SelectChip
                key={ageRange[0]}
                name={
                  ageRange[0] +
                  " - " +
                  ageRange[1] +
                  " " +
                  LocalizationStore.selectedLocale.exploreTasksPage.years
                }
                type={"age"}
                onClick={() =>
                  taskStore.AddOrRemoveAgeRangeFromtFilter(ageRange)
                }
              />
            ))}
        </div>
        <Typography variant="h6">
          {LocalizationStore.selectedLocale.exploreTasksPage.filterOnSubject}
        </Typography>
        <div style={{ margin: "0 -8px 25px" }}>
          {taskStore.subjectFilters.map((subject) => (
            <SelectChip
              key={subject}
              name={subject}
              type={"subject"}
              onDelete={() => taskStore.AddOrRemoveSubjectFromtFilter(subject)}
            />
          ))}
          {LocalizationStore.selectedLocale.task.availableSubjects
            .filter(
              (availableSubject) =>
                !taskStore.subjectFilters.includes(availableSubject)
            )
            .map((subject) => (
              <SelectChip
                key={subject}
                name={subject}
                type={"subject"}
                onClick={() => taskStore.AddOrRemoveSubjectFromtFilter(subject)}
              />
            ))}
        </div>
        <Typography variant="h6">
          {LocalizationStore.selectedLocale.exploreTasksPage.filterOnThemes}
        </Typography>
        <div style={{ margin: "0 -8px 25px" }}>
          {taskStore.themeFilters.map((theme) => (
            <SelectChip
              key={theme}
              name={theme}
              type={"theme"}
              onDelete={() => taskStore.AddOrRemoveThemeFromtFilter(theme)}
            />
          ))}
          {LocalizationStore.selectedLocale.task.availableThemes
            .filter(
              (availableTheme) =>
                !taskStore.themeFilters.includes(availableTheme)
            )
            .map((theme) => (
              <SelectChip
                key={theme}
                name={theme}
                type={"theme"}
                onClick={() => taskStore.AddOrRemoveThemeFromtFilter(theme)}
              />
            ))}
        </div>
        <Grid container spacing={3}>
          {taskStore.FilteredTasks.map((value, index) => {
            return (
              <Grid item key={index}>
                <TaskCard
                  task={value}
                  onClick={() => toggleTaskView(value.id)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Collapse>
      {taskStore.selectedTaskId !== null ? (
        <Collapse in={taskStore.selectedTaskId !== null} collapsedHeight={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              <Tooltip
                title={
                  LocalizationStore.selectedLocale.exploreTasksPage
                    .backButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={() => toggleTaskView(null)}>
                  <ArrowBack fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  LocalizationStore.selectedLocale.exploreTasksPage
                    .printButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={handlePrint}>
                  <Print fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  taskStore.IsSelectedTaskMine()
                  ? LocalizationStore.selectedLocale.myTasksPage.editButtonTooltip
                  : LocalizationStore.selectedLocale.exploreTasksPage.makeVariantButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={() => taskStore.MakeVariant()}>
                  <EditIcon fontSize="large"/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  taskStore.teacherMode
                    ? LocalizationStore.selectedLocale.exploreTasksPage
                        .studentViewButtonTooltip
                    : LocalizationStore.selectedLocale.exploreTasksPage
                        .teacherViewButtonTooltip
                }
                placement="bottom"
              >
                <IconButton onClick={() => taskStore.ToggleTeacherMode()}>
                  <School
                    fontSize="large"
                    color={taskStore.teacherMode ? undefined : "primary"}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div
              style={{ padding: "20mm 10mm 20mm 10mm", background: "white" }}
            >
              <div ref={componentRef}>
                <style>{getPageMargins()}</style>
                <ViewTask
                  task={taskStore.SelectedTask}
                  teacherMode={taskStore.teacherMode}
                />
              </div>
            </div>
          </div>
        </Collapse>
      ) : undefined}
    </div>
  );
}

export default observer(ExploreTasksPage);
