import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { menuItems } from "./menuItems";
import MyProjects from "./Components/Misc/MyProjects";
import HomePage from "./Components/Pages/HomePage";
import { observer } from "mobx-react";
import ExploreTasksPage from "./Components/Pages/ExploreTasksPage";
import EditTaskPage from "./Components/Pages/EditTaskPage";
import MyTasksPage from "./Components/Pages/MyTasksPage";
import SettingsPage from "./Components/Pages/SettingsPage";
import { Pages } from "./Stores/ViewStore";
import Copyright from "./Components/Primitives/Copyright";
import SignInPage from "./Components/Pages/SignInPage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F7F8FA",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "66px",
    [theme.breakpoints.up("sm")]: {
      width: "66px",
    },
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
  },
}));

const App = (props) => {
  var applicationStore = props.applicationStore;

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  if (!applicationStore.tokenStore.isAuthenticated) {
    applicationStore.viewStore.openSigninPage();
  }

  if (applicationStore.viewStore.page === "signin") {
    return <SignInPage applicationStore={applicationStore} />;
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={toggleDrawer}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconButton>
          </div>
          <List>{menuItems(applicationStore.viewStore)}</List>
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            {renderPage(applicationStore)}
            <Box pt={4}>
              {/* 
              <Copyright />
              */}
            </Box>
          </Container>
        </main>
      </div>
    );
  }
};

function renderPage(applicationStore) {
  switch (applicationStore.viewStore.page) {
    case Pages.HOME:
      return <HomePage applicationStore={applicationStore}/>;
    case Pages.NEW_TASK:
      return <EditTaskPage applicationStore={applicationStore} />;
    case Pages.MY_TASKS:
      return <MyTasksPage applicationStore={applicationStore} />;
    case Pages.EXPLORE_TASKS:
      return <ExploreTasksPage applicationStore={applicationStore} />;
    case Pages.MyProjects:
      return <MyProjects applicationStore={applicationStore} />;
    case Pages.SETTINGS:
      return <SettingsPage applicationStore={applicationStore} />;
    default:
      return "Sorry, we could not find what you were looking for.";
  }
}

export default observer(App);
