import Locale, {
    ExploreTasksPage,
    HomePage,
    MenuLocale,
    MyTasksPage,
    NewTaskForm,
    SettingsPage,
    Task,
  } from "../../Models/Locale";
  
  export const nnNO = Locale.create({
    locale: "nn-NO",
    name: "Norsk (nynorsk)",
    teacherPortal: "Lærarportal",
    menu: MenuLocale.create({
      newTask: "Ny Oppgåve",
      myTasks: "Mine Oppgåver",
      exploreTasks: "Utforsk Oppgåver",
      myProjects: "Mine Prosjekt",
      home: "Heim",
      signOut: "Logg ut",
    }),
    newTaskForm: NewTaskForm.create({
      language: "Språk",
      title: "Tittel",
      description: "Skildring",
      themes: "Tema",
      subjects: "Fag",
      ageRange: "Aldersgruppe",
      timeRange: "Tidsestimat",
      teacherDescriptionTitle: "Mål for undervisninga",
      teacherDescriptionPlaceholder:
        "Her kan du skriva mål for undervisninga, førebuingsnotat og anna læraren bør vita",
      studentDescriptionTitle: "Oppgåvetekst",
      studentDescriptionPlaceholder:
        "Her kan du skriva oppgåveteksten til elevane",
      saveButtonTooltip: "Lagra",
      previewButtonTooltip: "Førehandsvisning",
      clearButtonTooltip: "Blankt skjema",
      backButtonTooltip: "Tilbake",
      titleValidationErrorMessage: "Det oppstod ein feil: Oppgåva manglar tittel"
    }),
    homePage: HomePage.create({
      title: "Ludenso Create Lærarportal",
      aboutLudensoCreateTitle: "Kva er Ludenso Create?",
      aboutLudensoCreateDescription:
        "Ludenso Create lèt barn verda rundt skapa gjennom 3D og AR",
      navigateToLudensoCreateButtonText: "Prøv Ludenso Create!",
      aboutLudensoCreateTeacherPortalTitle: "Kva er lærarportalen?",
      aboutLudensoCreateTeacherPortalDescription:
        "Lærarportalen er laga for at lærarar enkelt kan bli inspirert, finna og laga pedagogisk materiale til Ludenso Create som er skreddarsydd dei nye lærarplanmåla.",
      featuredTaskTitle: "Utvald oppgåve",
    }),
    exploreTasksPage: ExploreTasksPage.create({
      title: "Finn oppgåver",
      filterOnAge: "Filtrer på alder:",
      filterOnSubject: "Filtrer på fag:",
      filterOnThemes: "Filtrer på tema:",
      estimatedtime: "Estimert tid",
      hours: "timar",
      years: "år",
      printButtonTooltip: "Skriv ut",
      backButtonTooltip: "Tilbake",
      teacherViewButtonTooltip: "Lærarvennlig",
      studentViewButtonTooltip: "Elevvennlig",
      makeVariantButtonTooltip: "Lag din eigen variant av oppgåva",
    }),
    myTasksPage: MyTasksPage.create({
      title: "Mine oppgåver",
      editButtonTooltip: "Rediger oppgåve",
      printButtonTooltip: "Skriv ut",
      backButtonTooltip: "Tilbake",
      teacherViewButtonTooltip: "Lærarvennlig",
      studentViewButtonTooltip: "Elevvennlig",
    }),
    settingsPage: SettingsPage.create({
      title: "Innstillingar",
      selectLanguage: "Vel språk",
    }),
    task: Task.create({
      availableAgeRanges: [
        [6, 7],
        [8, 9],
        [10, 12],
        [13, 15],
        [16, 18],
      ],
      availableSubjects: [
        "Matematikk",
        "Naturfag",
        "Norsk",
        "Kunst og handverk",
        "Historie",
        "Samfunnsfag",
        "Geografi",
        "Forretningsverksemd",
        "KRLE",
      ],
      availableThemes: [
        "Bærekraftig utvikling",
        "Demokrati og medborgarskap",
        "Folkehelse og livsmeistring",
        "Teknologi",
        "Forretningsverksemd",
        "Samarbeid",
        "Kommunikasjon",
        "Kritisk tenking",
        "21st century skills",
      ],
    }),
  });
  