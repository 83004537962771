import Locale, {
  ExploreTasksPage,
  HomePage,
  MenuLocale,
  MyTasksPage,
  NewTaskForm,
  SettingsPage,
  Task,
} from "../../Models/Locale";

// Southern Sami

export const smaNO = Locale.create({
  locale: "sma-NO",
  name: "Åarjelsaemien",
  teacherPortal: "Lohkehtæjjaportaale",
  menu: MenuLocale.create({
    newTask: "Orre laavenjasse",
    myTasks: "Mov laavenjassh",
    exploreTasks: "Goerehth laavenjassh",
    myProjects: "Mov prosjekth",
    home: "Gåatan",
    signOut: "Loggh olkese",
  }),
  newTaskForm: NewTaskForm.create({
    language: "Gïele",
    title: "Nomme",
    description: "Buerkiestimmie",
    themes: "Teema",
    subjects: "Faage",
    ageRange: "Aalteredåehkie",
    timeRange: "Tïjjeaarvehtse",
    teacherDescriptionTitle: "Ööhpehtimmien ulmie",
    teacherDescriptionPlaceholder:
      "Ööhpehtimmie",
    studentDescriptionTitle: "Laavenjasseteekste",
    studentDescriptionPlaceholder:
      "Laavenjasseteekste learoehkidie",
    saveButtonTooltip: "Vöörhkedh",
    previewButtonTooltip: "Åvtelhbodti vuesiehtimmie",
    clearButtonTooltip: "Gåaroes goere",
    backButtonTooltip: "Bååstede",
    titleValidationErrorMessage: "Fiejlie jijhti: Laavenjasse nommem fååtese"
  }),
  homePage: HomePage.create({
    title: "Ludenso Create Learoeportaale",
    aboutLudensoCreateTitle: "Mij Ludenso Create?",
    aboutLudensoCreateDescription:
      "Ludenso Create baaja maanah bïjre jarkan veartenisnie sjugniedidh 3D jïh AR:n tjïrrh",
    navigateToLudensoCreateButtonText: "Pryövh Ludenso Create!",
    aboutLudensoCreateTeacherPortalTitle: "Mij lohkehtæjjaportaale?",
    aboutLudensoCreateTeacherPortalDescription:
      "Lohkehtæjjaportaale dorjesovveme ihke lohkehtæjjah maehtieh skraejriem åadtjodh, pedagogeles materijellem Ludenso Createse gaavnedh jïh darjodh mah dejtie orre learoesoejkesjeulmide sjiehtieh.",
    featuredTaskTitle: "Veeljesovveme laavenjasse",
  }),
  exploreTasksPage: ExploreTasksPage.create({
    title: "Gaavnh laavenjassh",
    filterOnAge: "Veesmh aalteren mietie:",
    filterOnSubject: "Veesmh faagi mietie:",
    filterOnThemes: "Veesmh teemaj mietie:",
    estimatedtime: "Aerviedamme tïjje",
    hours: "tæjmoeh",
    years: "jaepieh",
    printButtonTooltip: "Tjaelieh olkese",
    backButtonTooltip: "Bååstede",
    teacherViewButtonTooltip: "Lohkehtæjjavietseles",
    studentViewButtonTooltip: "Learohkevietseles",
    makeVariantButtonTooltip: "Darjoeh jïjtjedh versjovnem laavenjasseste",
  }),
  myTasksPage: MyTasksPage.create({
    title: "Mov laavenjassh",
    editButtonTooltip: "Redigeerh laavenjassem",
    printButtonTooltip: "Tjaelieh olkese",
    backButtonTooltip: "Bååstede",
    teacherViewButtonTooltip: "Lohkehtæjjavietseles",
    studentViewButtonTooltip: "Learohkevietseles",
  }),
  settingsPage: SettingsPage.create({
    title: "Sjïehtesjimmieh ",
    selectLanguage: "Veeljh gïelem",
  }),
  task: Task.create({
    availableAgeRanges: [
      [6, 7],
      [8, 9],
      [10, 12],
      [13, 15],
      [16, 18],
    ],
    availableSubjects: [
      "Matematihke",
      "Eatnemefaage",
      "Nöörjen",
      "Tjeahpoe jïh vætnoe",
      "Histovrije",
      "Seabradahkefaage",
      "Geografije",
      "Entreprenørskaape",
      "KRJE"
    ],
    availableThemes: [
      "Nænnoes evtiedimmie",
      "Demokratije jïh meatanårrojevoete",
      "Almetjehealsoe jïh jieledehaalveme",
      "Teknologije",
      "Entreprenørskaape",
      "Laavenjostoe",
      "Gaskesadteme",
      "Laejhtehks ussjedimmie",
      "21st century skills"
    ],
  }),
});
