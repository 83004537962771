import axios from "axios";
import Cookies from "js-cookie";
import { types } from "mobx-state-tree";
import { BaseConnectionString } from "../utils/ConnectionStrings";
import Project from "../Models/Project";
import { Cookie } from "../utils/Cookie";

const ProjectStore = types
  .model("ProjectStore", {
    Projects: types.array(Project),
  })
  .actions((self) => ({
    LoadProjects() {
      axios.get(BaseConnectionString + "/api/getproject/my",
      {
        headers: {'Authorization': "Bearer " + Cookies.get(Cookie.AUTH_TOKEN)}
      })
      .then(e => {
        self.SetProjects(e.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    SetProjects(projects) {
      self.Projects = projects;
    },
    AddProject(project) {
      self.Projects.push(project);
    },
  }));

export default ProjectStore;
