import { types, clone } from "mobx-state-tree";

const MAX_TITLE_LENGTH = 100;
const MAX_SUBTITLE_LENGTH = 250;

const Task = types
  .model("Task", {
    id: types.maybe(types.integer),
    parentId: types.maybeNull(types.integer),
    cultureName: types.string,
    minTime: types.string,
    maxTime: types.string,
    minAge: types.integer,
    maxAge: types.integer,
    title: types.string,

    subTitle: types.maybeNull(types.string),
    description: types.string,
    teacherDescription: types.string,

    themes: types.array(types.string),
    subjects: types.array(types.string),
  })
  .views((self) => ({
    get ageRange() {
      return [self.minAge, self.maxAge];
    },
    get timeRange() {
      return [self.minTime.substr(0, 2), self.maxTime.substr(0, 2)];
    },
    get formattedMinTime() {
      return self.minTime.substr(0, 2) * 1;
    },
    get formattedMaxTime() {
      return self.maxTime.substr(0, 2) * 1;
    },
  }))
  .actions((self) => ({
    SetText(text) {
      self.description = text;
    },
    SetTeacherDescription(text) {
      self.teacherDescription = text;
    },
    SetThemes(themes) {
      self.themes = themes;
    },
    SetSubjects(subjects) {
      self.subjects = subjects;
    },
    SetTitle(title) {
      self.title = title.substring(0, MAX_TITLE_LENGTH);
    },
    SetSubtitle(subTitle) {
      self.subTitle = subTitle.substring(0, MAX_SUBTITLE_LENGTH);
    },
    SetAgeRange(ageRange) {
      self.minAge = ageRange[0];
      self.maxAge = ageRange[1];
    },
    SetTimeRage(timeRange) {
      self.minTime = new Date(timeRange[0] * 60 * 60 * 1000)
        .toISOString()
        .substr(11, 8);
      self.maxTime = new Date(timeRange[1] * 60 * 60 * 1000)
        .toISOString()
        .substr(11, 8);
    },
    SetCultureName(cultureName) {
      self.cultureName = cultureName;
    },
    MakeChild() {
      self.parentId = self.id;
      self.id = undefined;
    },
    GetClone() {
      var clonedTask = clone(self);
      clonedTask.MakeChild();
      return clonedTask;
    },
  }));

export { Task };
